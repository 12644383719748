@mixin inputStyle {
  font-size: 1rem;
  font-family: $baseType;
  border-radius: 4px;
  border: none;
  padding: .5rem;
  width: 100%;
  box-sizing: border-box;
  box-shadow: $boxShadow;
}

@mixin submitStyle {
  border-radius: 4px;
  outline: none;
  border: none;
  padding: .5rem;
  font-family: $baseType;
  font-size: 1rem;
  background: $mainColor;
  color: white;
  width: 8rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: all 150ms;
  font-weight: $hFW;

  &:hover {
    box-shadow: $boxShadow;
  }

  &.disabled {
    pointer-events: none;
    background: $lightGray;
    color: $medGray;
  }
}

#auth {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input[type=password] {
    @include inputStyle;
    width: 15rem;
    margin-bottom: 1rem;
  }

  button {
    @include submitStyle;
    width: 15rem;
    margin: 0;
  }

  p.error {
    color: $red;
  }
}

#run {
  min-height: 100vh;
  overflow: scroll;
  width: 100vw;
  //background: $mainColor;
  padding: 2.5rem;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  &.show-map {
    .Map-Container {
      opacity: 1;
      cursor: crosshair;
      pointer-events: all;
    }
  }
}

.message-container {
  border-radius: 3px;
  width: 100%;
  padding: .5rem;
  margin-bottom: 1rem;
  background: $mainColor;
  box-sizing: border-box;

  p {
    color: white;
    font-family: $baseType;
    font-size: 1rem;
    //font-weight: $hFW;
    margin: 0;
  }
}

//styling for layout
.content-container {
  display: grid;
  grid-template-columns: 6fr 2fr;
  grid-column-gap: 1rem;
}

.Address-Container {
  flex: auto;
  margin-bottom: 1rem;
  display: flex;
  max-height: 2.5rem;
  position: relative;

  input {
    @include inputStyle;
  }

  button.submit {
    @include submitStyle;
  }

  .results-container {
    position: absolute;
    right: 0;
    left: 0;
    background: white;
    display: flex;
    flex-direction: column;
    top: 3rem;
    box-sizing: border-box;
    z-index: 2000;
    box-shadow: $boxShadow;
    overflow: scroll;
    display: none;

    &.shown {
      display: flex;
    }

    button {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      cursor: pointer;

      .result {
        padding: .5rem;
        width: 100%;
        height: 2.5rem;
        display: flex;
        border-bottom: 1px solid gray;
        align-items: center;
        box-sizing: border-box;

        p {margin: 0; font-size: 1rem;}
      }

      &:last-of-type .result {
        border-bottom: none;
      }

      &:hover .result {
        background: $lightGray;
      }
    }
  }


}

.Loading-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
}

.Map-Container {
  min-height: 22rem;
  height: 30rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: all 200ms;
}

.Marker {
  height: .75rem;
  width: .75rem;
  border-radius: 50%;
  //opacity: .8;
  background: $mainColor;
  box-shadow: $boxShadow;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  span { color: white; }
}

.Marker.Center {
  background: white;
  pointer-events: none;
  img {
    height: 2rem;
    width: 2rem;
  }
}

.Marker.Elevation {
  background: $red;
}

.Usage-Meter-Container {
  background: white;
  padding: 1rem;
  text-align: center;

  p {
    margin: 0;
    margin-bottom: .5rem;
  }

  .body {
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1.5rem;
      color: black;
    }
  }

  .subtitle {
    p {
      font-size: .75rem;
      margin: 0;
    }
  }
}

.Directions-Container {
  background: white;
  padding: 1rem;
  max-height: 30rem;
  overflow-y: scroll;
}

.Directions-Container p.title {
  font-size: 1.15rem;
  margin: 0;
  padding-bottom: 1rem;
}

.Directions-Container p.no-content {
  font-size: .85rem;
  margin: auto;
  padding: .5rem;
  text-align: center;
}

.Directions-Container .steps {
  display: flex;
  flex-direction: column;

  .step {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: .25rem; 
    padding-bottom: .25rem;

    p {
      margin: 0;
      font-size: .85rem;
    }

    p.distance {
      flex: 0 0 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p.instruction {
      line-height: 18px;
      font-size: .75rem;
      flex: auto;
    }

    input[type=checkbox] {
      flex: 0 0 2rem;
    }
  }
}



@media only screen and (max-width: 768px) {
  #run {
    padding: .5rem;

    .content-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 1rem;
    }

    .Elevation-Map {
      overflow: scroll;
    }

    .Address-Container {
      margin-bottom: .5rem;
    }

    .Controls button:hover, .Controls button {
      background: white;
    }
  }
}

