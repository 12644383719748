body {
  -webkit-font-smoothing: antialiased;
  background-color: $white;
}

a {
  color: $medGray;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus {
    outline: none;
  }
}

a, h5, p, label { color: $medGray; }

.App .messages {
  margin: .75rem 3rem 1rem 3rem;
  position: fixed;
  z-index: 5000;
  left: 0;
  right: 0;
}

.notice, .alert {
    position: absolute;
    left: 50%;
    color: white;
    transform: translateX(-50%);
    z-index: 5000;
    margin: 0;
    margin-top: 1rem;
    font-size: 1rem;
    font-size: 1rem;
    padding: .25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 5px;
    font-family: $obj3Reg;

    i {
      font-weight: 800;
      padding-right: 7px;
      position: relative;
      bottom: 1px;
      cursor: pointer;
    }
}

.notice {
    background-color: $green;
}

.alert {
    background-color: $red;
}

body { background-color: $lightGray;}

a.button {
  background: $medGray;
  color: white;
  border-radius: 3px;
  margin: 0;
  border: 0;
  transition: all 100ms;
  font-size: 1rem;
  font-family: $baseType;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &:hover {
    box-shadow: $boxShadow;
  }

}

