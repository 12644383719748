//@font-face {
//  font-family: 'briteicons';
//  src:  font-url('briteicons.ttf')  format('truetype');
//}

$blue: #004E64;
$offWhite: #BFC3BA;
$white: #ffffff;
$navy: #031927;
$green: #4BC6B9;
$lightBlue: #9DD1F1;
$red: #ff5c74;
$mainColor: #44A1A0;


$tierOne: #FCFCFC;
$tierOneAcc: #ABAEBD;

$tierTwo: #F2FCF9;
$tierTwoAcc: #8CE1C8;

$tierThree: #F8FDFF;
$tierThreeAcc: #8EDCFF;

$twitterBlue: #d9edf7;

$darkGray:    #202A51;
$medGray:   #616680;
$lightGray:		#f5f6f8;
$offGray:   #9499AC;
$black:			#000000;
$boxShadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);

//new
$actionColor: #2D848A;


$bluePale:  #CFEDFC;
$lighterGray: #F5F8FA;
$lightestGray: #f4f7f9;
$grayTrim:  #DADADA;

// Typography
$base-font-family: 'Raleway', 'arial';
$heading-font-family: 'Raleway', 'arial';

$obj3Med:   'Raleway', 'arial';
$obj3Reg:   'Raleway', 'arial';
$obj3Bold:  'Raleway', 'arial';

$baseType: $base-font-family;
$headerType: $heading-font-family;
$hFW: 600;


// Font Colors
$base-background-color: #ffffff;
$base-font-color: $darkGray;
$action-color: $darkGray;

// Border
$base-border-color: $darkGray;

//box shadow
$boxShadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);


@mixin transformY {
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY (-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

@mixin transformX {
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

//mixins
@mixin textField {
  border-radius: 3px;
  border: none;
  padding: 5px;
  padding-left: 10px;
  font-family: $obj3Reg;
  font-size: 1rem;
  background-color: $lightGray;
  //box-shadow: $boxShadow;

  &:focus {
    outline: none;
  }
}

@mixin platformLink {
  text-decoration: underline;
  font-size: .85rem;
  font-family: $obj3Reg;
  border: none;
  $box-shadow: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:focus {
  	outline: none;
  }
}

button.link {
	color: $actionColor;
	border: none;
	text-decoration: underline;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.notification {
	padding: .5rem;
	background-color: $twitterBlue;
	width: 100%;
	border: 1px solid $lightGray;
  max-width: 50vw;
  margin: auto;
  margin-bottom: .5rem;
	position: relative;
	border-radius: .25rem;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
  display: grid;
  grid-template-columns: 95% 5%;

  &:last-of-type {
		margin-bottom: 0;
	}

  p, button {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    /* text-align: center; */
    z-index: 5000;
  }

	p {
			margin: 0;
			font-family: $obj3Med;
			color: $medGray;

			a {
				color: $medGray;
				text-decoration: underline;
			}
	}

	&.error {
		background-color: $red;

		p {
			color: white;

			a {
				color: white;
			}
		}
	}

  button.remove-message {
    cursor: pointer;
  	i.cancel-message {
      font-size: 1.5rem;
      color: white;
      cursor: pointer;
      position: relative;
      margin: auto;
      display: block;
      width: 100%;
  	}

    &:focus {
      outline: none;
    }
  }
}

.container.loading {
	pointer-events: none;
	button, input[type=submit] {
		background-color: $lightGray;
		border: 1px solid $darkGray;
		color: $darkGray;
	}
}

.App.loading {
  input[type=submit] {
    pointer-events: none;
    background-color: $lightGray !important;
    color: $medGray !important;
    border: 1px solid $medGray !important;
  }
}

@mixin buttonStyle {
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: all 150ms;
  background: $actionColor;
  color: white;
  font-size: 1rem;

  &:hover {
    box-shadow: $boxShadow;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: .7;
  }
}

input.button {
  @include buttonStyle;
}

button.button {
  @include buttonStyle;
}

button.danger {
  background: $red;
}
