
.box__platform {
  width: 40rem;
  top: 3rem;
	margin: auto;
	margin-bottom: 1rem;
	background-color: white;
	border-radius: 2px;
	position: relative;
	border: .5px solid #c7c7c7;
	padding: 2rem 2rem 4rem;
	@media only screen and (max-width: 768px) { width: initial; }

	&.large {
		width: 80%;
	}

	&.no-nav {
		top: 2rem;
	}

	h5 {
		text-align: center;
		color: $darkGray;
		font-family: $obj3Reg;
		font-size: 1.75rem;
		margin: 0;
		margin-bottom: .5rem;

    a {
      font-size: 1rem;
      text-decoration: underline;
      color: $actionColor;
    }
	}

	.fields {
		width: 66%;
		margin: auto;

		.field {
			margin-bottom: 1rem;
		}
	}

	.fields .field input, .fields .field button.button {
		width: 100%;
		padding: .5rem;
		border-radius: 3px;
		box-shadow: one;
		//background-color: $lightGray;
		box-sizing: border-box;

		&:focus {
			outline: none;

		}
	}

	.fields .actions {
		width: 100%;

		input[type=submit] {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			width: 100%;
	    font-size: 1rem;
	    padding: .5rem;
	    /* height: 8rem; */
	    border: none;
	    border-radius: 3px;
	    color: white;
	    background-color: #529dc4;
	    margin-bottom: 1rem;
		}
	}

	p {
		font-size: .85rem;
		font-family: $obj3Reg;
		margin-bottom: .5rem;
		a {
			color: $green;
			text-decoration: underline;
		}

		&.squish {
			margin-bottom: .25rem;
		}

		&.green {
			color: $green;
		}

		&.upgrade-comment { text-align: left; }

    &.center { text-align: center; }
	}


	&.no-padding.small {
		top: 6rem;
	}
}

@media only screen and (max-width: 768px) {
	.box__platform {
		width: 100%;
		box-sizing: border-box;
		padding: 1rem;
		border: none;
		box-shadow: none;
		padding-top: 0;
		top: 2rem;

		.logo { display: none; }
		.links {margin-top: 0;}

		p.squish {
			margin-bottom: .75rem;
			font-size: .8rem;
		}

		.fields {
			width: 80%;
		}

		&.no-mobile {
			display: none;
		}

		&.large { 
			width: 100%; 
		}

	}
}