.Controls {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: white;
  box-shadow: $boxShadow;
  cursor: initial;
  display: flex;
  align-items: center;

  button {
    border: 0;
    outline: none;
    border: none;
    margin: 0; 
    padding: 0;
    cursor: pointer;
    transition: all 200ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: .5rem;
    padding-right: .5rem;
    background: white;

    &:hover {
      background: $lightGray;
    }

    img {
      height: 1.5rem;
      padding: .5rem;
      padding-bottom: 0;
      cursor: pointer;
    }

    label {
      padding-bottom: .5rem;
      cursor: pointer;
      font-weight: $hFW;
    }
  }

  .metric {
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
      font-size: 1rem;
    }
  }
}