.Elevation-Map {
  margin-top: 1rem;
  background: white;
  padding: 2rem;
  position: relative;
  box-sizing: border-box;

  .y-label, .x-label {
    position: absolute;
    font-size: .85rem;
    font-family: $baseType;
  }

  .y-label {
    position: absolute;
    left: 1.25rem;
    top: 41%;
    //transform: rotate(270deg) translateY(-50%);
    transform:  translateX(-50%) rotate(-90deg);;
  }

  .x-label {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
}